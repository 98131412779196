import { useMemo } from 'react';
import checkSupportBrowser from '../../lib/checkSupportBrowser';
import { watchMyGeoLocation, watchMyGeoLocationOnSafari } from '../../lib/getFeatureFlags';
import { useOrderLocation } from '../../redux/hooks';
import { MOBILE_SAFARI, SAFARI } from '../constants/supportedBrowsers';
import useWatchDistance from './useWatchGeoDistance';

interface useWatchPickupAddressDistance {
    distance: string;
    loading: boolean;
}

/**
 * Tracks the distance between device coords and pickup address coords
 * @param {number} destinationLatitude
 * @param {number} destinationLongitude
 * @returns {IUseWatchDistanceHook} distance (string, 1 number after decimal, ex. '34.6') in miles between device coords and pickup address coords and undefined if device geolocation is not available
 * @example
 * // returns distance to pickup store calculated based on device location
 * const { distance } = useWatchPickupAddressDistance(52.0961155, 23.7408617);
 */
const useWatchPickupAddressDistance = () => {
    const watchGeoPosition = watchMyGeoLocation();
    const watchGeolocationOnSafari = watchMyGeoLocationOnSafari();
    const { pickupAddress } = useOrderLocation();

    const isEnabled = useMemo(() => {
        if (watchGeoPosition && pickupAddress) {
            const { name } = checkSupportBrowser();
            if (!watchGeolocationOnSafari && [SAFARI, MOBILE_SAFARI].includes(name)) {
                return false;
            }

            return true;
        }
    }, [watchGeoPosition, pickupAddress, watchGeolocationOnSafari]);

    const args = isEnabled ? [pickupAddress?.details?.latitude, pickupAddress?.details?.longitude] : [];

    const { distance, loading } = useWatchDistance(...args);

    return { distance: distance?.toFixed(1), loading };
};

export default useWatchPickupAddressDistance;
