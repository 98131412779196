import React from 'react';
import classnames from 'classnames';

import styles from './dropdown.module.css';
import Dropdown from '../../../atoms/dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import { PICKUP_SHEVRON_SIZE, SHOW_PICKUP_TIME_ASTERISK } from '../constants';

interface IDropdownComponent {
    value: string;
    name?: string;
    label: string;
    placeholder?: string;
    options: { value: string; label: string; hint?: string }[];
    error?: string;
    className?: string;
    labelClassName?: string;
    selectClassName?: string;
    errorClassName?: string;
    buttonClassName?: string;
    listClassName?: string;
    selectedItemClassName?: string;
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    onChange?: (value: string) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLSelectElement>) => void;
    defaultValue?: string;
    required?: boolean;
    disabled: boolean;
    readOnly?: boolean;
    readOnlyErrorState?: boolean;
}

interface IDropdownTarget {
    text: string;
    hint?: string;
}
export const DropdownTarget = ({ text, hint }: IDropdownTarget): JSX.Element => {
    return (
        <p>
            {text}
            {hint && <span className={styles.labelHint}>{hint}</span>}
        </p>
    );
};

const DropdownComponent = ({
    value,
    label,
    placeholder,
    options,
    className,
    labelClassName,
    errorClassName,
    buttonClassName,
    listClassName,
    selectedItemClassName,
    onChange,
    disabled,
    error,
    readOnly,
    readOnlyErrorState,
}: IDropdownComponent): JSX.Element => {
    const groupClasses = classnames(styles.inputGroup, {
        [className]: !!className,
    });

    const selectedOption = options.find((o) => o.value === value);
    const selectedValueLabel = value && selectedOption ? selectedOption.label : placeholder;
    const selectedOptionHint = value && selectedOption ? selectedOption.hint : null;

    return (
        <div className={groupClasses}>
            <span
                className={classnames(styles.inputLabel, {
                    [labelClassName]: !!labelClassName,
                })}
            >
                {label}
                {SHOW_PICKUP_TIME_ASTERISK && <span className={styles.asterisk}> *</span>}
            </span>
            <div data-testid={label}>
                {readOnly ? (
                    <input
                        readOnly={true}
                        placeholder={placeholder}
                        value={value}
                        className={classnames('t-paragraph', styles.readOnlyInput, {
                            [styles.readOnlyInputError]: readOnlyErrorState,
                        })}
                    />
                ) : (
                    <Dropdown
                        listClassName={classnames(styles.listClassName, {
                            [listClassName]: !!listClassName,
                        })}
                        optionsListClassName={classnames(styles.optionsListClassName)}
                        buttonClassName={classnames({
                            [styles.dropdownError]: error,
                            [buttonClassName]: !!buttonClassName,
                        })}
                        target={<DropdownTarget text={selectedValueLabel} hint={selectedOptionHint} />}
                        disabled={disabled}
                        flipEnabled
                        iconSize={PICKUP_SHEVRON_SIZE}
                    >
                        {options.map((o) => (
                            <MenuItem
                                tabIndex={0}
                                key={o.value}
                                className={classnames(styles.dropdownItem, {
                                    [styles.selectedDropdownItem]: o.value === value && !selectedItemClassName,
                                    [selectedItemClassName]: o.value === value && !!selectedItemClassName,
                                })}
                                onClick={() => {
                                    onChange(o.value);
                                }}
                            >
                                {o.label}
                                {o.hint && (
                                    <span className={classnames('t-paragraph-hint', styles.labelHint)}>
                                        &nbsp;{o.hint}
                                    </span>
                                )}
                            </MenuItem>
                        ))}
                    </Dropdown>
                )}
            </div>
            {error && (
                <div
                    className={classnames(styles.error, {
                        [errorClassName]: !!errorClassName,
                    })}
                >
                    {error}
                </div>
            )}
        </div>
    );
};

export default DropdownComponent;
