import React from 'react';
import classNames from 'classnames';
import Icon from '../../../atoms/BrandIcon';
import { StoreStatusText } from './index';
import { NAV_LOCATION_ICON } from './constants';

import styles from './index.module.css';

interface ILocationBlockProps {
    className?: string;
    infoClassName?: string;
    iconClassName?: string;
    chevronIconClassName?: string;
    chevronIcon?: string;
    storeStatusText: string;
    storeDisplayName: string;
    mobileOnly?: boolean;
}

const LocationBlock: React.FC<ILocationBlockProps> = ({
    className,
    infoClassName,
    iconClassName,
    chevronIconClassName,
    chevronIcon,
    storeStatusText,
    storeDisplayName,
    mobileOnly,
}) => {
    const isSelectLocationText = storeStatusText === StoreStatusText.SELECT_LOCATION;
    const shoulShowInfo = !isSelectLocationText || storeDisplayName;
    return (
        <div
            className={classNames(className || styles.locationBlock, { [styles.mobileOnly]: mobileOnly })}
            tabIndex={-1}
        >
            {shoulShowInfo && <span className={infoClassName || styles.info}>{storeStatusText}</span>}
            <Icon className={iconClassName || styles.icon} icon={NAV_LOCATION_ICON} paths={3} />
            <span className={styles.name}>
                <span className={classNames('truncate', styles.nameText)}>
                    {!shoulShowInfo ? storeStatusText : storeDisplayName}
                </span>
                {chevronIcon && (
                    <Icon
                        role={'status'}
                        className={chevronIconClassName || styles.chevronIcon}
                        icon={chevronIcon}
                        size="tiny"
                    />
                )}
            </span>
        </div>
    );
};

export default LocationBlock;
