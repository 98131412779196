import { IServiceTypeModel, THourDayOfWeekModel } from '../../@generated/webExpApi';

export const LocationsDaysModel = {
    Sun: THourDayOfWeekModel.Sunday,
    Mon: THourDayOfWeekModel.Monday,
    Tue: THourDayOfWeekModel.Tuesday,
    Wed: THourDayOfWeekModel.Wednesday,
    Thu: THourDayOfWeekModel.Thursday,
    Fri: THourDayOfWeekModel.Friday,
    Sat: THourDayOfWeekModel.Saturday,
};

export const LocationServicesModel = {
    [IServiceTypeModel.Wifi]: 'Wifi',
    [IServiceTypeModel.BlazingRewards]: 'Blazin’ Rewards',
    [IServiceTypeModel.GoLocation]: 'GO Location',
    [IServiceTypeModel.ExpressLocation]: 'EXPRESS Location',
    [IServiceTypeModel.UfcViewing]: 'UFC Viewing',
    [IServiceTypeModel.Delivery]: 'Delivery',
    [IServiceTypeModel.DriveIn]: 'Drive-in',
    [IServiceTypeModel.DriveThru]: 'Drive-thru',
    [IServiceTypeModel.OnlineOrdering]: 'Online Ordering',
};
