import isEmpty from 'lodash/isEmpty';
import { IHoursModel, ILocationServiceHoursModel } from '../../../@generated/webExpApi';
import { LocationsDaysModel } from '../../constants/locations';

const transformHoursByDay = (hoursByDay: { [key: string]: ILocationServiceHoursModel }): IHoursModel[] => {
    if (!hoursByDay || isEmpty(hoursByDay)) {
        return null;
    }

    const daysOfWeek = Object.keys(hoursByDay);
    return daysOfWeek.reduce((hours, day) => {
        const dayHours = {
            dayOfWeek: LocationsDaysModel[day],
            startTime: hoursByDay[day].start,
            endTime: hoursByDay[day].end,
            isTwentyFourHourService: hoursByDay[day].isOpen24Hs,
        };

        if (dayHours.startTime && dayHours.endTime) {
            hours.push(dayHours);
        }

        return hours;
    }, [] as IHoursModel[]);
};

export default transformHoursByDay;
