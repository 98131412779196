import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './timeSlots.module.css';
import classnames from 'classnames';
import DropdownComponent from '../checkout/pickupDeliveryInfo/dropdown';
import { useBag, useOrderLocation } from '../../../redux/hooks';
import { IAvailableTimeModel, IAvailableTimesModel, TServiceTypeModel } from '../../../@generated/webExpApi';
import { formatTZ, isAfter, isSameDay, utcToZonedTime } from '../../../common/helpers/dateTime';
import { resolveOpeningHours } from '../../../lib/locations';
import { addASAPFieldToRange } from '../../../common/helpers/checkoutHelpers';
import { LocationAvailableTimes, OrderLocationMethod } from '../../../redux/orderLocation';

import { getHoursWithTimezone } from '../../../lib/locations/resolveOpeningHours';
import transformHoursByDay from '../../../common/helpers/locations/transformHoursByDay';
import { useLocationTimeZone } from '../../../common/hooks/useLocationTimeZone';
import { useBagAnalytics } from '../../../common/hooks/useBagAnalytics';
import { addMinutes } from 'date-fns';
import useLocationAvailableTimes from '../../../common/hooks/useLocationAvailableTimes';
import { TIME_NEEDED_FOR_KITCHEN_TO_PREPARE, USER_CHECKOUT_INTERACTION } from '../../../common/constants/timeslots';
import { capitalize } from '../../../common/helpers/capitalize';

export interface ILocationTimeSlotsProps {
    method: OrderLocationMethod;
    onCheckoutInvalidTimeError?: string;
    clearInvalidTimeError?: () => void;
}

const todayLabel = 'Today';
const asapValue = 'asap';
const dayFormat = 'MM/dd/yyyy';

const TimeSlots: FC<ILocationTimeSlotsProps> = (props): JSX.Element => {
    const { method, onCheckoutInvalidTimeError, clearInvalidTimeError } = props;
    const {
        pickupTime,
        pickupTimeType,
        deliveryTime,
        deliveryTimeType,
        actions: { setPickupTime },
    } = useBag();

    const time = method === OrderLocationMethod.DELIVERY ? deliveryTime : pickupTime;
    const timeType = method === OrderLocationMethod.DELIVERY ? deliveryTimeType : pickupTimeType;

    const { pushGtmChangeOrderDate } = useBagAnalytics();

    const timeDropdownLabel = `${capitalize(method)} time`;
    const dateDropdownLabel = `${capitalize(method)} date`;

    const getFormattedDay = (date: Date) => formatTZ(date, dayFormat, { timeZone: locationTimeZone });

    const {
        pickupLocationTimeSlots,
        deliveryLocationTimeSlots,
        deliveryAddress,
        pickupAddress,
        getFilteredPickUpSlots,
        actions: { setPickupLocationAvailableTimeSlots, setDeliveryLocationAvailableTimeSlots },
    } = useOrderLocation();
    const { getLocationAvailableTimeSlots } = useLocationAvailableTimes();

    const pickUpLocation = deliveryAddress?.pickUpLocation;

    const locationTimeZone = useLocationTimeZone();

    const availableTimeSlots = useMemo(
        () => (method === OrderLocationMethod.DELIVERY ? deliveryLocationTimeSlots : pickupLocationTimeSlots),
        [deliveryLocationTimeSlots, pickupLocationTimeSlots, method]
    );
    const storeIsOpenNow = useMemo(() => {
        if (method === OrderLocationMethod.DELIVERY && !pickUpLocation?.hoursByDay) {
            return false;
        }

        const resolvedOpeningHours =
            method === OrderLocationMethod.DELIVERY
                ? getHoursWithTimezone(transformHoursByDay(pickUpLocation.hoursByDay), locationTimeZone)
                : resolveOpeningHours(pickupAddress, TServiceTypeModel.OrderAhead);

        return !!resolvedOpeningHours && resolvedOpeningHours.isOpen;
    }, [pickUpLocation, pickupAddress, method, locationTimeZone]);

    const timeIntervalsNotSetForDelivery =
        method === OrderLocationMethod.DELIVERY &&
        availableTimeSlots &&
        availableTimeSlots.delivery &&
        availableTimeSlots.delivery.byDay &&
        Object.keys(availableTimeSlots.delivery.byDay).length === 0;

    const [availableTimesByDay, setAvailableTimesByDay] = useState<{ [key: string]: Array<IAvailableTimeModel> }>(null);
    const [timeSlotsDay, setTimeSlotsDay] = useState<{ day: string; timeRanges: IAvailableTimeModel[] }>(null);
    const [availableDays, setAvailableDays] = useState<{ value: string; label: string; hint?: string }[]>(null);
    const [availableTimes, setAvailableTimes] = useState<{ value: string; label: string; hint?: string }[]>(null);

    const formatDates = (date: string) => {
        //date has format yyyy-MM-dd
        if (date) {
            const splitDate = date.split('-');
            return splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0];
        }
        return null;
    };

    const filterAvailableTimes = (): void => {
        const availableTimes = (method === OrderLocationMethod.DELIVERY
            ? availableTimeSlots.delivery
            : availableTimeSlots.pickup
        )?.byDay;
        if (!availableTimes) return;

        const timeWeek = Object.keys(availableTimes).reduce((acc, key) => {
            return { ...acc, [formatDates(key)]: availableTimes[key] };
        }, {});

        const currentTime = utcToZonedTime(new Date(Date.now()), locationTimeZone);
        const day = getFormattedDay(currentTime);

        if (timeWeek[day]) {
            const times = timeWeek[day]?.filter((time) =>
                isAfter(utcToZonedTime(new Date(time.utc), locationTimeZone), currentTime)
            );
            timeWeek[day] = times;
        }
        setAvailableTimesByDay(timeWeek);
    };

    const checkValidityOfSlots = (timeslots: IAvailableTimesModel) => {
        const availableTimes = timeslots.byDay;
        if (availableTimes) {
            const currentTime = utcToZonedTime(new Date(Date.now()), locationTimeZone);
            return Object.values(availableTimes).some((timesPerDay) =>
                timesPerDay.some((time) => isAfter(utcToZonedTime(new Date(time.utc), locationTimeZone), currentTime))
            );
        }
        return false;
    };

    const loadTimeSlots = (updateSlotsMethod: (timeslots: LocationAvailableTimes) => void, locationId: string) => {
        if (updateSlotsMethod && locationId) {
            getLocationAvailableTimeSlots({ locationId }).then((timeslots) => {
                updateSlotsMethod(timeslots);
            });
        }
    };

    useEffect(() => {
        if (method === OrderLocationMethod.PICKUP && pickupLocationTimeSlots) {
            if (!checkValidityOfSlots(pickupLocationTimeSlots.pickup)) {
                loadTimeSlots(setPickupLocationAvailableTimeSlots, pickupAddress?.id);
            }
        }
        if (method === OrderLocationMethod.DELIVERY && deliveryLocationTimeSlots) {
            if (!checkValidityOfSlots(deliveryLocationTimeSlots.delivery)) {
                loadTimeSlots(setDeliveryLocationAvailableTimeSlots, deliveryAddress?.locationDetails?.id);
            }
        }
    }, [pickupLocationTimeSlots, deliveryLocationTimeSlots]);

    useEffect(() => {
        const currentTime = utcToZonedTime(new Date(Date.now()), locationTimeZone);
        if (!isAfter(utcToZonedTime(new Date(time), locationTimeZone), currentTime)) {
            setPickupTime({
                time: null,
                asap: true,
                method,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    useEffect(() => {
        if (!availableTimeSlots) return;
        filterAvailableTimes();
        const interval = setInterval(filterAvailableTimes, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTimeSlots]);

    useEffect(() => {
        if (!availableTimesByDay) return;
        const now = new Date(Date.now());
        const nowTimezoned = formatTZ(utcToZonedTime(now, locationTimeZone), 'yyyy-MM-dd');
        const availableDays = Object.keys(availableTimesByDay).map((day) => {
            const label = day === formatDates(nowTimezoned) ? `${todayLabel} (${day})` : day;
            return { value: day, label };
        });

        setAvailableDays(availableDays);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTimesByDay]);

    useEffect(() => {
        if (!(timeSlotsDay?.timeRanges?.length > 0)) return;
        let availableTimeRanges = timeSlotsDay.timeRanges.map((time) => ({
            value: time.utc.toString(),
            label: time.display.toLowerCase(),
        }));

        if (storeIsOpenNow && timeSlotsDay.timeRanges[0]?.utc) {
            availableTimeRanges = addASAPFieldToRange({
                timeRange: availableTimeRanges,
                selectedDay: timeSlotsDay?.timeRanges[0].utc.toString(),
                timezone: locationTimeZone,
                prepTime: null,
                asapWithoutBrackets: true,
                isAdditionalASAP: method === OrderLocationMethod.DELIVERY,
            });
        }

        setAvailableTimes(availableTimeRanges);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSlotsDay]);

    useEffect(() => {
        if (!availableTimesByDay) return;

        if (timeType !== asapValue && time) {
            const selectedDay = Object.keys(availableTimesByDay).find((day) => {
                const pickupTimeWithTimezone = utcToZonedTime(new Date(time), locationTimeZone);
                return isSameDay(new Date(day), pickupTimeWithTimezone);
            });

            if (!selectedDay) return;

            setTimeSlotsDay({ day: selectedDay, timeRanges: availableTimesByDay[selectedDay] });
        } else {
            setTimeSlotsDay(getFirstAvailableDay());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTimesByDay, time]);

    useEffect(() => {
        if (onCheckoutInvalidTimeError) {
            const twentyThreeMinutesFromNow = addMinutes(
                utcToZonedTime(new Date(Date.now()), locationTimeZone),
                TIME_NEEDED_FOR_KITCHEN_TO_PREPARE + USER_CHECKOUT_INTERACTION
            );
            setPickupLocationAvailableTimeSlots(getFilteredPickUpSlots(twentyThreeMinutesFromNow));
            setPickupTime({ asap: true, time: null, method });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCheckoutInvalidTimeError, method, locationTimeZone]);

    const getFirstAvailableDay = () => {
        const availableTimes = Object.keys(availableTimesByDay);
        if (availableTimes?.length > 0) {
            const firstDay = availableTimes[0];
            return { day: firstDay, timeRanges: availableTimesByDay[firstDay] };
        }
        return null;
    };

    const handlePickupDayChange = (value: string) => {
        const newTimeSlotsDayTimes = availableTimesByDay ? availableTimesByDay[value] : null;
        const firstNewTimeSlotsDayTimesUtc =
            newTimeSlotsDayTimes && newTimeSlotsDayTimes.length > 0 ? newTimeSlotsDayTimes[0].utc : null;
        setTimeSlotsDay({ day: value, timeRanges: newTimeSlotsDayTimes });
        const isFirstDaySelected = availableDays[0].value === value;

        setPickupTime({
            time: isFirstDaySelected && storeIsOpenNow ? null : `${firstNewTimeSlotsDayTimesUtc}`,
            asap: isFirstDaySelected && storeIsOpenNow,
            method,
        });

        if (firstNewTimeSlotsDayTimesUtc) {
            const selectedDaySlot = new Date(firstNewTimeSlotsDayTimesUtc).toISOString();
            pushGtmChangeOrderDate(method, selectedDaySlot);
        }
        clearInvalidTimeErrorStatus();
    };

    const handleOrderTimeChange = (value: string) => {
        (availableTimes?.length > 0 &&
            value === availableTimes[0].value &&
            getFormattedDay(new Date(value)) === availableDays[0].value) ||
        value === null
            ? setPickupTime({ asap: true, time: null, method })
            : setPickupTime({ time: value, asap: false, method });

        const selectedTimeSlot = new Date(value).toISOString();
        pushGtmChangeOrderDate(method, selectedTimeSlot);
        clearInvalidTimeErrorStatus();
    };

    const clearInvalidTimeErrorStatus = () => {
        onCheckoutInvalidTimeError && clearInvalidTimeError && clearInvalidTimeError();
    };

    if ((!availableDays || !availableTimes) && !timeIntervalsNotSetForDelivery) return null;

    return (
        <>
            <div className={styles.wrapper}>
                <DropdownComponent
                    value={timeIntervalsNotSetForDelivery ? 'Unavailable' : timeSlotsDay?.day}
                    label={dateDropdownLabel}
                    labelClassName={styles.label}
                    buttonClassName={styles.buttonLabel}
                    listClassName={classnames('t-paragraph', styles.list)}
                    selectedItemClassName={styles.selectedItem}
                    name="day"
                    placeholder={dateDropdownLabel}
                    options={availableDays ?? []}
                    onChange={handlePickupDayChange}
                    disabled={false}
                    readOnly={timeIntervalsNotSetForDelivery}
                    readOnlyErrorState={true}
                />
                <DropdownComponent
                    value={timeIntervalsNotSetForDelivery ? 'Unavailable' : timeType === asapValue ? asapValue : time}
                    label={timeDropdownLabel}
                    labelClassName={styles.label}
                    buttonClassName={styles.buttonLabel}
                    listClassName={classnames('t-paragraph', styles.list)}
                    selectedItemClassName={styles.selectedItem}
                    name="time"
                    placeholder={
                        timeIntervalsNotSetForDelivery
                            ? ''
                            : `${(availableTimes?.length > 0 && availableTimes[0].label) || timeDropdownLabel}`
                    }
                    options={availableTimes ?? []}
                    onChange={handleOrderTimeChange}
                    disabled={false}
                    readOnly={timeIntervalsNotSetForDelivery}
                    readOnlyErrorState={true}
                />
            </div>
            {onCheckoutInvalidTimeError && (
                <div className={styles.invalidTimeErrorContainer}>
                    <p className={classnames('t-paragraph-small', styles.invalidTimeErrorLabel)}>
                        {onCheckoutInvalidTimeError}
                    </p>
                </div>
            )}
            {timeIntervalsNotSetForDelivery && (
                <p
                    data-testid={'selectPickupMessage'}
                    className={classnames('t-paragraph', styles.deliveryTimesErrors)}
                >
                    Select either pickup or a different location
                </p>
            )}
        </>
    );
};

export default TimeSlots;
