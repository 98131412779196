import React, { useState } from 'react';
import { useRouter } from 'next/router';

import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import Input from '../../../../atoms/Input/input';
import { InspireButton } from '../../../../atoms/button';

import styles from './pickupLocationFind.module.css';

import { OrderLocationMethod } from '../../../../../redux/orderLocation';
import { LocationTabPaths } from '../../../locationsPageContent/locationsPageContentPickupAndDeliveryFlow/types';
import { useLocationSearchUrl } from '../../../../../common/hooks/useLocationSearchUrl';

interface ILocationFindProps {
    closeDropdown: () => void;
    method: OrderLocationMethod;
}

const PickupLocationFind = (props: ILocationFindProps): JSX.Element => {
    const { method, closeDropdown } = props;

    const router = useRouter();
    const [searchValue, setSearchValue] = useState('');
    const { backToParam, searchLocationPath } = useLocationSearchUrl();

    const isUseMyLocationEnabled = method === OrderLocationMethod.PICKUP;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchValue(e.target.value);
    };

    const handleGeolocationClick = () => {
        navigator.geolocation.getCurrentPosition((location) => {
            const { latitude, longitude } = location.coords;
            sessionStorage.setItem('myLocation', [latitude, longitude].join(','));
            closeDropdown();
            router.push(searchLocationPath);
        });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClick();
        }
    };

    const handleClick = () => {
        closeDropdown();

        let query = {};
        if (method === OrderLocationMethod.PICKUP)
            query = { t: LocationTabPaths.PICKUP, q: searchValue, ...backToParam };
        if (method === OrderLocationMethod.DELIVERY)
            query = { t: LocationTabPaths.DELIVERY, dq: searchValue, ...backToParam };

        router.push({ pathname: searchLocationPath, query });
    };

    return (
        <div className={styles.locationFindWrapper}>
            {isUseMyLocationEnabled && (
                <>
                    <InspireButton
                        className={styles.searchButton}
                        type="primary"
                        text="use my location"
                        onClick={handleGeolocationClick}
                    />
                    <div className={styles.separator}>
                        <span>or</span>
                    </div>
                </>
            )}
            <div className={styles.searchInput}>
                <Input
                    leftIcon={isUseMyLocationEnabled && <NearMeOutlinedIcon className={styles.searchLeftIcon} />}
                    searchIconAriaLabel="Search Location"
                    searchInputAriaLabel="Search Location Input"
                    onKeyPress={handleKeyPress}
                    onClick={handleClick}
                    onChange={handleChange}
                    type="search"
                    placeholder="Search by Address, City and State or Zip Code"
                    inputDataTestId="search-location-input"
                    searchButtonDataTestId="search-location-button"
                />
            </div>
        </div>
    );
};

export default PickupLocationFind;
