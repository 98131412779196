import { useMemo } from 'react';
import { useOrderLocation, useBag, useConfiguration } from '../../../redux/hooks';
import { isLocationClosed, isLocationOrderAheadAvailable } from '../../../lib/locations';
import { getOpeningHoursInfo } from '../../helpers/locations/getOpeningHoursInfo';
import getLocationDetailsPageUrl from '../../helpers/getLocationDetailsPageUrl';
import getChangeLocationText from './getChangeLocationText';
import getAddress from './getAddress';
import getDisplayName from './getDisplayName';
import getLocationButtonCtaText from './getLocationButtonCtaText';

export interface UsePickupTabHook {
    title: string;
    displayName?: string;
    detailsPageUrl?: string;
    address: string;
    phone?: string;
    storeStatus?: string;
    shouldHighlightStatus?: boolean;
    changeLocationText: string;
    ctaTooltip?: string;
    ctaText: string;
}

export const usePickupTab = (): UsePickupTabHook => {
    const {
        configuration: { isOAEnabled },
    } = useConfiguration();
    const { pickupAddress } = useOrderLocation();
    const bag = useBag();

    return useMemo(() => {
        const changeLocationText = getChangeLocationText();

        if (pickupAddress) {
            const isOnlineOrderAvailable = isLocationOrderAheadAvailable(pickupAddress, isOAEnabled);
            const isStatusClosed = isLocationClosed(pickupAddress);
            const isOnlineOrderUnavailable = isOnlineOrderAvailable && !isStatusClosed;

            let storeStatus: string = null;
            let shouldHighlightStatus: boolean;
            if (pickupAddress.status) {
                const openingHoursInfo = getOpeningHoursInfo(pickupAddress);
                storeStatus = openingHoursInfo?.statusText;
                shouldHighlightStatus = openingHoursInfo?.shouldHighLight;
            }

            const address = getAddress(pickupAddress);

            const displayName = getDisplayName(pickupAddress);

            return {
                title: isOnlineOrderUnavailable ? 'Pickup location' : 'Preferred location',
                displayName,
                detailsPageUrl: getLocationDetailsPageUrl(pickupAddress),
                address,
                phone: pickupAddress.contactDetails?.phone,
                storeStatus,
                changeLocationText,
                shouldHighlightStatus,
                ctaTooltip:
                    !isOnlineOrderUnavailable && `Online ordering not available for store ${pickupAddress.displayName}`,
                ctaText: getLocationButtonCtaText(bag.bagEntriesCount, isOnlineOrderUnavailable),
            };
        }

        return {
            title: null,
            displayName: null,
            detailsPageUrl: null,
            address: null,
            phone: null,
            storeStatus: null,
            changeLocationText,
            ctaTooltip: null,
            ctaText: null,
        };
    }, [pickupAddress, isOAEnabled, bag.bagEntriesCount]);
};
