import React, { useCallback, memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiInput from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';

import styles from './input.module.css';
import classNames from 'classnames';

interface IInputProps {
    defaultValue?: string;
    onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    placeholder?: string;
    type?: 'search' | 'regular';
    searchIconAriaLabel?: string;
    searchInputAriaLabel?: string;
    leftIcon?: JSX.Element;
    disabled?: boolean;
    inputDataTestId?: string;
    searchButtonDataTestId?: string;
}

const Input = ({
    defaultValue,
    onClick,
    onChange,
    className,
    onKeyPress,
    placeholder,
    type = 'regular',
    searchIconAriaLabel = '',
    searchInputAriaLabel = '',
    leftIcon,
    disabled,
    inputDataTestId,
    searchButtonDataTestId,
}: IInputProps): JSX.Element => {
    const handleChange = useCallback(
        (e) => {
            if (typeof onChange === 'function') {
                onChange(e);
            }
        },
        [onChange]
    );

    const handleClick = useCallback(
        (e) => {
            if (typeof onClick === 'function') {
                onClick(e);
            }
        },
        [onClick]
    );

    const handleKeyPress = useCallback(
        (e) => {
            if (typeof onKeyPress === 'function') {
                onKeyPress(e);
            }
        },
        [onKeyPress]
    );

    if (type === 'search') {
        return (
            <div className={styles.searchInputContainer}>
                <div className={styles.searchInputRightBlock}>
                    {leftIcon && leftIcon}
                    <MuiInput
                        // key is assigned defaultValue to make input rerender if defaultValue changes
                        // i.e. when we want to keep input synced with external value but want to keep input uncontrolled
                        key={defaultValue}
                        defaultValue={defaultValue}
                        disableUnderline
                        classes={{
                            root: classNames(className, styles.input, styles.searchInput, {
                                [styles.withIcon]: !!leftIcon,
                            }),
                        }}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        placeholder={placeholder}
                        inputProps={{ 'aria-label': searchInputAriaLabel }}
                        disabled={disabled}
                        data-testid={inputDataTestId}
                    />
                </div>
                <IconButton onClick={handleClick} aria-label={searchIconAriaLabel} data-testid={searchButtonDataTestId}>
                    <SearchIcon classes={{ root: styles.searchIcon }} />
                </IconButton>
            </div>
        );
    }

    return (
        <MuiInput
            className={classNames(styles.input, className)}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            disabled={disabled}
            data-testid={inputDataTestId}
        />
    );
};

export default memo(Input);
