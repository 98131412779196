import { ILocationWithDetailsModel } from '../../@generated/webExpApi/models';
import { PreviousLocation, OrderLocationMethod } from '../../redux/orderLocation';
import { IDeliveryAddressObject } from '../services/locationService/types';

export const getPreviousLocationData = ({
    method,
    pickupAddress,
    deliveryAddress,
}: {
    method: OrderLocationMethod;
    pickupAddress: ILocationWithDetailsModel;
    deliveryAddress: IDeliveryAddressObject;
}): PreviousLocation => {
    if (method === OrderLocationMethod.PICKUP && pickupAddress) {
        return pickupAddress;
    }

    if (method === OrderLocationMethod.DELIVERY && deliveryAddress) {
        return deliveryAddress;
    }

    return null;
};
