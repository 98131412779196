import React, { useMemo, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { isLocationClosed, isLocationOrderAheadAvailable } from '../../../../../lib/locations';
import { usePickupTab } from '../../../../../common/hooks/usePickupTab';

import NavigationDropdown from '../../navigationDropdown/navigationDropdown';
import LocationMethodTab from './locationTabs/locationMethodTab';
import LocationTabs from './locationTabs';

import { useConfiguration, useOrderLocation } from '../../../../../redux/hooks';
import styles from './locationDropdown.module.css';

import { OrderLocationMethod } from '../../../../../redux/orderLocation';
import { GTM_SEARCH_NEW_LOCATION } from '../../../../../common/services/gtmService/constants';
import { useBagAnalytics } from '../../../../../common/hooks/useBagAnalytics';
import { useLocationSearchUrl } from '../../../../../common/hooks/useLocationSearchUrl';

interface ILocationDropdownProps {
    locationDropdownClassName?: string;
    locationDropdownModalStyle?: React.CSSProperties;
    locationDropdownModalClassName?: string;
    disablePortal?: boolean;
    navRef?: React.MutableRefObject<HTMLElement>;
    controlRef?: React.MutableRefObject<HTMLElement>;
    isDropdownOpen: boolean;
    closeDropdown: () => void;
    onCheckoutInvalidTimeError?: string;
    clearInvalidTimeError?: () => void;
}

const LocationDropdown = (props: ILocationDropdownProps): JSX.Element => {
    const {
        locationDropdownClassName,
        locationDropdownModalStyle,
        locationDropdownModalClassName,
        disablePortal,
        navRef,
        controlRef,
        isDropdownOpen,
        closeDropdown,
        onCheckoutInvalidTimeError,
        clearInvalidTimeError,
    } = props;
    const { pickupAddress } = useOrderLocation();
    const pickupTabData = usePickupTab();
    const router = useRouter();
    const dispatch = useDispatch();
    const {
        configuration: { isOAEnabled, isDeliveryEnabled },
    } = useConfiguration();
    const { pushGtmLocationOrder, pushGtmChangeLocation } = useBagAnalytics();

    const isOnlineOrderUnavailable = useMemo(() => {
        if (pickupAddress) {
            const isOnlineOrderAvailable = isLocationOrderAheadAvailable(pickupAddress, isOAEnabled);
            const isStatusClosed = isLocationClosed(pickupAddress);
            return !(isOnlineOrderAvailable && !isStatusClosed);
        }
        return true;
    }, [pickupAddress, isOAEnabled]);
    const { backToParam, searchLocationPath } = useLocationSearchUrl();

    const handleChangeLocationClick = (e: SyntheticEvent) => {
        e.preventDefault();
        closeDropdown();
        dispatch({ type: GTM_SEARCH_NEW_LOCATION });
        const zip = pickupAddress?.contactDetails?.address?.postalCode;

        pushGtmChangeLocation();

        return router.push({ pathname: searchLocationPath, query: { q: zip, intop: true, ...backToParam } });
    };

    const handleStartOrderClick = () => {
        closeDropdown();
        if (isOnlineOrderUnavailable) {
            const zip = pickupAddress?.contactDetails?.address?.postalCode;
            return router.push({ pathname: searchLocationPath, query: { q: zip, intop: true, ...backToParam } });
        }

        pushGtmLocationOrder(OrderLocationMethod.PICKUP, pickupAddress?.id);

        return router.push('/menu');
    };

    return (
        <NavigationDropdown
            open={isDropdownOpen}
            onClose={() => closeDropdown()}
            controlRef={controlRef}
            navRef={navRef}
            modalStyle={locationDropdownModalStyle}
            disablePortal={disablePortal}
            modalClassName={locationDropdownModalClassName}
            className={classNames(styles.navigationDropdown, locationDropdownClassName)}
            dataTestId="location-dropdown"
        >
            <div className={classNames(styles.navigationDropdownContent)}>
                {isDeliveryEnabled ? (
                    <LocationTabs
                        closeDropdown={closeDropdown}
                        onCheckoutInvalidTimeError={onCheckoutInvalidTimeError}
                        clearInvalidTimeError={clearInvalidTimeError}
                    />
                ) : (
                    <LocationMethodTab
                        {...pickupTabData}
                        method={OrderLocationMethod.PICKUP}
                        handleChangeLocationClick={handleChangeLocationClick}
                        handleStartOrderClick={handleStartOrderClick}
                        closeDropdown={closeDropdown}
                        onCheckoutInvalidTimeError={onCheckoutInvalidTimeError}
                        clearInvalidTimeError={clearInvalidTimeError}
                    />
                )}
            </div>
        </NavigationDropdown>
    );
};

export default LocationDropdown;
