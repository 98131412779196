/**
 * @namespace checkSupportBrowser
 */
import UAParser from 'ua-parser-js';
import supportedBrowsers from '../common/constants/supportedBrowsers';

/**
 * @typedef checkSupportBrowserReturnValue
 * @memberOf checkSupportBrowser
 * @property {boolean} isCurrentBrowserSupported
 * @property {boolean} isMobile
 * @property {string} name
 */

/**
 * @deprecated since 11-14-2022 for all brands, currently web app is not following any type of unsupported browser.
 * @method checkSupportBrowser
 * @memberOf checkSupportBrowser
 * @description Checks and returns if browser is supported, if it's a mobile device and the name of the OS
 * @added 11-14-2022
 * @return {checkSupportBrowserReturnValue}
 * @example
 * const { isCurrentBrowserSupported, isMobile, name } = checkBrowserSupport(0;
 * if (isCurrentBrowserSupported) {
 *     // Do not supported browser here
 *     ...
 *     if (isMobile) {
 *         //Dedicated not supported logic for mobile devices here
 *         ...
 *     }
 *     if(regex.test(name)) {
 *         //Dedicated user agent logic here
 *         ...
 *     }
 * }
 */
const checkSupportBrowser = (): {
    isCurrentBrowserSupported: boolean;
    isMobile: boolean;
    name: string;
} => {
    const parser = new UAParser();

    const { name, version } = parser.getBrowser();
    const { name: osName } = parser.getOS();

    const { type, model } = parser.getDevice();

    const majorVersion = version?.split('.')[0];

    const checkOsName = model?.includes('Xbox') ? 'Windows Xbox' : osName;

    const supportedCurrentBrowser = supportedBrowsers.find(
        (browser) => browser.name === name && browser.os === checkOsName
    );

    const isMobile = ['mobile', 'tablet'].includes(type);

    if (supportedCurrentBrowser && (!supportedCurrentBrowser?.major || !majorVersion)) {
        return {
            isCurrentBrowserSupported: true,
            isMobile,
            name,
        };
    }

    const isCurrentBrowserSupported =
        supportedCurrentBrowser && Number(supportedCurrentBrowser.major.split('.')[0]) <= Number(majorVersion);

    return {
        isCurrentBrowserSupported,
        isMobile,
        name,
    };
};

export default checkSupportBrowser;
