import { zonedTimeToUtc } from 'date-fns-tz';
import { TServiceTypeModel } from '../../@generated/webExpApi';
import { resolveOpeningHours } from '../../lib/locations';
import { useBag, useOrderLocation } from '../../redux/hooks';
import { OrderLocationMethod } from '../../redux/orderLocation';
import { useAppDispatch } from '../../redux/store';
import { enhanceDaysWithLabel, enhanceTimesWithLabel } from '../helpers/checkoutHelpers';
import { GTM_CHANGE_LOCATION, GTM_LOCATION_ORDER } from '../services/gtmService/constants';
import { GtmCategory } from '../services/gtmService/types';

export const useBagAnalytics = () => {
    const dispatch = useAppDispatch();
    const { method, pickupAddress, deliveryAddress, currentLocation: location } = useOrderLocation();
    const { orderTime } = useBag();

    const serviceType = method === OrderLocationMethod.PICKUP ? TServiceTypeModel.Pickup : TServiceTypeModel.Delivery;

    const getSelectedDateTime = (orderTime: string) => {
        const openingHours = resolveOpeningHours(location, serviceType);

        const localStoreTime = orderTime ? zonedTimeToUtc(orderTime, openingHours?.storeTimezone) : new Date();

        const localStoreTimeString = localStoreTime.toISOString();

        const days = enhanceDaysWithLabel([localStoreTimeString], openingHours?.storeTimezone);
        const selectedDate = days && days.length > 0 ? days[0].label : '';

        const parsedPickupTime = new Date(localStoreTime).toISOString();
        const times = enhanceTimesWithLabel([parsedPickupTime], openingHours?.storeTimezone);

        const selectedTime = orderTime ? times[0].label : 'ASAP';

        return { selectedDate, selectedTime };
    };

    const pushGtmChangeOrderDate = (method: OrderLocationMethod, orderTime: string) => {
        const { selectedDate, selectedTime } = getSelectedDateTime(orderTime);

        const locationId =
            method === OrderLocationMethod.PICKUP ? pickupAddress.id : deliveryAddress?.locationDetails?.id;

        const payload = {
            category: GtmCategory.ORDER,
            action: 'Change Handoff Time',
            label: locationId,
            date: selectedDate,
            time: selectedTime,
        };

        dispatch({
            type: GTM_LOCATION_ORDER,
            payload,
        });
    };

    const pushGtmLocationOrder = (method: OrderLocationMethod, locationId: string) => {
        const { selectedDate, selectedTime } = getSelectedDateTime(orderTime);

        const payload = {
            category: GtmCategory.ORDER,
            action: `Click ${method}`,
            label: locationId,
            date: selectedDate,
            time: selectedTime,
        };

        dispatch({
            type: GTM_LOCATION_ORDER,
            payload,
        });
    };

    const pushGtmChangeLocation = () => {
        const payload = {
            category: GtmCategory.ORDER,
            action: 'Change Address',
            label: location.id,
        };

        dispatch({
            type: GTM_CHANGE_LOCATION,
            payload,
        });
    };

    return {
        pushGtmLocationOrder,
        pushGtmChangeOrderDate,
        pushGtmChangeLocation,
    };
};
