import React, { useRef } from 'react';

import classnames from 'classnames';

import {
    isLocationClosed,
    isLocationOrderAheadAvailable,
    resolveOpeningHours,
    getBrandDefaultStatusText,
    getDeliveryAddressText,
} from '../../../../lib/locations';

import LocationDropdown from './locationDropdown';
import NavigationTooltip from '../navigationTooltip';

import styles from './index.module.css';

import { TServiceTypeModel } from '../../../../@generated/webExpApi';
import { OrderLocationMethod } from '../../../../redux/orderLocation';
import { titleStoreNotSelected } from '../constants';
import { useConfiguration, useOrderLocation } from '../../../../redux/hooks';

import LocationBlock from './locationBlock';
import { SELECT_ADDRESS_LINE1 } from './constants';

interface ILocationSelectProps {
    setDropdownToggle: (boolean) => void;
    isDropdownOpen: boolean;
    className?: string;
    navRef?: React.MutableRefObject<HTMLElement>;
    titleTooltip?: string;
    infoClassName?: string;
    locationBlockClassName?: string;
    iconClassName?: string;
    chevronIconClassName?: string;
    containerClassName?: string;
    locationDropdownClassName?: string;
    locationDropdownModalStyle?: React.CSSProperties;
    locationDropdownModalClassName?: string;
    disablePortal?: boolean;
    isArbysMobile?: boolean;
    onCheckoutInvalidTimeError?: string;
    clearInvalidTimeError?: () => void;
}

export enum StoreStatusText {
    DELIVER_TO = 'Deliver to',
    STORE_CLOSING_SOON = 'Store closing soon',
    STORE_OPENING_SOON = 'Store opening soon',
    PICKUP_FROM = 'Pickup from',
    SELECT_LOCATION = 'Select Location',
}

function LocationSelect(props: ILocationSelectProps): JSX.Element {
    const {
        className,
        navRef,
        titleTooltip,
        infoClassName,
        locationBlockClassName,
        iconClassName,
        chevronIconClassName,
        containerClassName,
        locationDropdownClassName,
        locationDropdownModalStyle,
        locationDropdownModalClassName,
        disablePortal,
        isArbysMobile,
        setDropdownToggle,
        isDropdownOpen,
        onCheckoutInvalidTimeError,
        clearInvalidTimeError,
    } = props;

    const { method, pickupAddress, deliveryAddress } = useOrderLocation();
    const {
        configuration: { isOAEnabled },
    } = useConfiguration();
    const locationTextRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => setDropdownToggle(!isDropdownOpen);
    const closeDropdown = () => setDropdownToggle(false);
    const chevronIcon = isDropdownOpen ? 'direction-up' : 'direction-down';

    const getStoreStatusText = (): string => {
        if (method === OrderLocationMethod.DELIVERY) return StoreStatusText.DELIVER_TO;
        if (method === OrderLocationMethod.PICKUP) {
            /** Case: You can't order from this store via website
             * !isLocationOrderAheadAvailable -> store is not digitally enabled(can't order online from this store via website)
             * isLocationClosed -> store is closed(example` It's closed for renovation)
             */
            if (!isLocationOrderAheadAvailable(pickupAddress, isOAEnabled) || isLocationClosed(pickupAddress))
                return getBrandDefaultStatusText();

            const { isOpen, isOpeningSoon, isClosingSoon } = resolveOpeningHours(
                pickupAddress,
                TServiceTypeModel.OrderAhead
            );

            /** Cases where you can order from website
             * the [isOpen, isOpeningSoon, isClosingSoon] booleans will generate the 8(2^3) possible scenarios.
             * But some of the scenarios are not valid for us.
             *      Example` Store can't be isOpen and isOpeningSoon at the same time. It just not make sense.
             * The valid scenarios will be marked with (+), invalid ones with (-).
             *  Order [isOpen, isOpeningSoon, isClosingSoon] used in scenarios
             * 1.   + [false, false, false] -> Store is closed at this time of day | "Pickup from"
             * 2.   + [true, false, false] -> Store is open at this time of day | "Pickup from"
             * 3.   + [false, true, false] -> Store is closed BUT will be open soon | "Store opening soon"
             * 4.   - [false, false, true]
             * 5.   - [true, true, false]
             * 6.   + [true, false, true] -> Store is open BUT will be closed soon | "Store closing soon"
             * 7.   - [false, true, true]
             * 8.   - [true, true, true]
             */

            /** Scenario 6
             * Since the scenario "8" is not valid anyway, so we can ignore the "isOpeningSoon" check
             */
            if (isOpen && isClosingSoon) return StoreStatusText.STORE_CLOSING_SOON;

            /** Scenario 3
             * Since the scenario "7" is not valid anyway, so we can ignore the "isClosingSoon" check
             */
            if (!isOpen && isOpeningSoon) return StoreStatusText.STORE_OPENING_SOON;

            /** Scenario 1 and 2
             * These 2 scenarios have same response
             */
            return StoreStatusText.PICKUP_FROM;
        }

        /* the only other method is "OrderLocationMethod.NOT_SELECTED" */
        return StoreStatusText.SELECT_LOCATION;
    };

    const getStoreDisplayName = (): string => {
        if (method === OrderLocationMethod.DELIVERY) return getDeliveryAddressText(deliveryAddress);
        if (method === OrderLocationMethod.PICKUP) {
            return SELECT_ADDRESS_LINE1 ? pickupAddress.contactDetails.address.line1 : pickupAddress.displayName;
        }

        // The BWW will show a button is location not selected, only Arby's will get ot case bellow
        return titleStoreNotSelected;
    };

    return (
        <NavigationTooltip titleTooltip={titleTooltip} isDropdownOpen={isDropdownOpen}>
            <div className={classnames(className, containerClassName || styles.container)} ref={locationTextRef}>
                {!isArbysMobile && (
                    <div
                        className={classnames(styles.locationBlockWrapper, {
                            [styles.disabled]: !isOAEnabled,
                        })}
                        onClick={toggleDropdown}
                        onKeyPress={toggleDropdown}
                        tabIndex={0}
                        data-testid={'locationBlock'}
                        role={'button'}
                    >
                        <LocationBlock
                            className={locationBlockClassName}
                            infoClassName={infoClassName}
                            iconClassName={iconClassName}
                            chevronIconClassName={chevronIconClassName}
                            chevronIcon={chevronIcon}
                            storeStatusText={getStoreStatusText()}
                            storeDisplayName={getStoreDisplayName()}
                        />
                    </div>
                )}
                <LocationDropdown
                    locationDropdownClassName={locationDropdownClassName}
                    locationDropdownModalStyle={locationDropdownModalStyle}
                    locationDropdownModalClassName={locationDropdownModalClassName}
                    disablePortal={disablePortal}
                    navRef={navRef}
                    controlRef={locationTextRef}
                    isDropdownOpen={isDropdownOpen}
                    closeDropdown={closeDropdown}
                    onCheckoutInvalidTimeError={onCheckoutInvalidTimeError}
                    clearInvalidTimeError={clearInvalidTimeError}
                />
            </div>
        </NavigationTooltip>
    );
}

export default LocationSelect;
