import React, { SyntheticEvent } from 'react';
import classnames from 'classnames';

import InspirePhoneLink from '../../../../../../atoms/phoneLink';
import InspireTooltip from '../../../../../../atoms/Tooltip';
import { InspireLink, InspireLinkButton } from '../../../../../../atoms/link';
import { InspireButton } from '../../../../../../atoms/button';
import TimeSlots from '../../../../../../organisms/timeSlots';
import PickupLocationFind from '../../../pickupLocationFind';

import styles from './locationMethodTab.module.css';

import { UsePickupTabHook } from '../../../../../../../common/hooks/usePickupTab';
import { OrderLocationMethod } from '../../../../../../../redux/orderLocation';
import { locationTimeSlotsEnabled } from '../../../../../../../lib/getFeatureFlags';
import { SHOW_UNAVAILABLE, TITLE_TYPOGRAPHY, UNAVAILABLE_MESSAGE } from './constants';
import Loader from '../../../../../../atoms/Loader';
import TextWithDotSeparator from '../../../../../../atoms/TextWithDotSeparator';
import useWatchPickupAddressDistance from '../../../../../../../common/hooks/useWatchPickupAddressGeoDistance';

interface ILocationMethodTabProps extends UsePickupTabHook {
    method: OrderLocationMethod;
    handleStartOrderClick: () => void;
    handleChangeLocationClick: (e: SyntheticEvent) => void;
    closeDropdown: () => void;
    onCheckoutInvalidTimeError?: string;
    clearInvalidTimeError?: () => void;
}

const LocationMethodTab = (props: ILocationMethodTabProps): JSX.Element => {
    const {
        method,
        title,
        displayName,
        detailsPageUrl,
        address,
        phone,
        storeStatus,
        shouldHighlightStatus,
        changeLocationText,
        ctaTooltip,
        ctaText,
        handleStartOrderClick,
        handleChangeLocationClick,
        closeDropdown,
        onCheckoutInvalidTimeError,
        clearInvalidTimeError,
    } = props;

    const { distance, loading } = useWatchPickupAddressDistance();

    if (!address) {
        return (
            <div className={styles.methodTabWrapper}>
                <PickupLocationFind method={method} closeDropdown={closeDropdown} />
            </div>
        );
    }

    const isLocationTimeSlotsFeatureEnabled = locationTimeSlotsEnabled();

    return (
        <div className={styles.methodTabWrapper}>
            <div className={styles.backgroundLine} />
            <div className={styles.detailsContainer}>
                <div className={classnames(TITLE_TYPOGRAPHY, styles.title)}>{title}</div>
                {displayName && (
                    <div
                        title={displayName}
                        className={classnames(
                            't-subheader-smaller',
                            {
                                [styles.withLink]: detailsPageUrl,
                                'truncate-at-2': !detailsPageUrl,
                            },
                            styles.displayName
                        )}
                    >
                        {detailsPageUrl ? (
                            <InspireLink
                                link={detailsPageUrl}
                                newtab={false}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className={classnames({ 'truncate-at-2': detailsPageUrl })}
                            >
                                {displayName}
                            </InspireLink>
                        ) : (
                            displayName
                        )}
                    </div>
                )}
                <p title={address} className={classnames('t-paragraph-small', styles.address)}>
                    {address}
                </p>
                {phone && <InspirePhoneLink className={styles.phoneLink} variant="secondary" phone={phone} />}
                {method !== OrderLocationMethod.DELIVERY && (
                    <p
                        className={classnames('t-paragraph-hint', styles.statusText, {
                            [styles.highlight]: shouldHighlightStatus,
                        })}
                        title="Store Status and Hours"
                    >
                        <TextWithDotSeparator
                            leftText={
                                loading ? (
                                    <Loader className={styles.loader} size={14} />
                                ) : (
                                    !!distance && <>{distance} mi</>
                                )
                            }
                            rightText={storeStatus || (SHOW_UNAVAILABLE ? UNAVAILABLE_MESSAGE : null)}
                        />
                    </p>
                )}

                <InspireLinkButton
                    className={classnames(styles.changeLocationButton, {
                        [styles.changeLocationButtonDelivery]: method === OrderLocationMethod.DELIVERY,
                    })}
                    onClick={handleChangeLocationClick}
                    linkType="secondary"
                >
                    {changeLocationText}
                </InspireLinkButton>
            </div>
            {isLocationTimeSlotsFeatureEnabled && (
                <TimeSlots
                    method={method}
                    onCheckoutInvalidTimeError={onCheckoutInvalidTimeError}
                    clearInvalidTimeError={clearInvalidTimeError}
                />
            )}
            {ctaTooltip ? (
                <InspireTooltip title={ctaTooltip} placement="top" theme="light" arrow>
                    <div className={styles.startOrderCtaWrapper}>
                        <InspireButton
                            type="primary"
                            text={ctaText}
                            onClick={handleStartOrderClick}
                            dataTestId="start-order-cta"
                            fullWidth
                        />
                    </div>
                </InspireTooltip>
            ) : (
                <div className={styles.startOrderCtaWrapper}>
                    <InspireButton
                        type="primary"
                        text={ctaText}
                        onClick={handleStartOrderClick}
                        dataTestId="start-order-cta"
                        fullWidth
                    />
                </div>
            )}
        </div>
    );
};

export default LocationMethodTab;
