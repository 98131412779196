//os
const WINDOWS = 'Windows';
const WINDOWS_XBOX = 'Windows Xbox';
const MACOS = 'Mac OS';
const ANDROID = 'Android';
const iOS = 'iOS';
const LINUX = 'Linux';

//browser
export const CHROME = 'Chrome';
export const FIREFOX = 'Firefox';
export const SAFARI = 'Safari';
export const MOBILE_SAFARI = 'Mobile Safari';
export const SAMSUNG_BROWSER = 'Samsung Browser';
export const EDGE = 'Edge';
export const IE = 'IE';
export const OPERA = 'Opera';

//app-in-app
const FACEBOOK = 'Facebook';
const INSTAGRAM = 'Instagram';
const WEBKIT = 'WebKit'; // iOS
const CHROME_WEBVIEW = 'Chrome WebView'; // android

const supportedBrowsers = [
    {
        name: CHROME,
        major: '73',
        os: WINDOWS,
    },
    {
        name: CHROME,
        major: '73',
        os: MACOS,
    },
    {
        name: CHROME,
        major: '73',
        os: ANDROID,
    },
    {
        name: CHROME,
        major: '73',
        os: iOS,
    },
    {
        name: CHROME,
        major: '73',
        os: LINUX,
    },
    {
        name: FIREFOX,
        major: '58',
        os: WINDOWS,
    },
    {
        name: FIREFOX,
        major: '58',
        os: MACOS,
    },
    {
        name: FIREFOX,
        major: '58',
        os: ANDROID,
    },
    {
        name: FIREFOX,
        major: '29',
        os: iOS,
    },
    {
        name: MOBILE_SAFARI,
        major: '10',
        os: iOS,
    },
    {
        name: SAFARI,
        major: '10',
        os: iOS, // for snapchat
    },
    {
        name: SAFARI,
        major: '10',
        os: MACOS,
    },
    {
        name: SAMSUNG_BROWSER,
        major: '9',
        os: ANDROID,
    },
    {
        name: EDGE,
        major: '79',
        os: WINDOWS,
    },
    {
        name: EDGE,
        major: '44',
        os: WINDOWS_XBOX,
    },
    {
        name: EDGE,
        major: '79',
        os: MACOS,
    },
    {
        name: EDGE,
        major: '44',
        os: ANDROID,
    },
    {
        name: EDGE,
        major: '44',
        os: iOS,
    },
    {
        name: IE,
        major: '11',
        os: WINDOWS,
    },
    {
        name: OPERA,
        major: '76',
        os: WINDOWS,
    },
    {
        name: OPERA,
        major: '76',
        os: MACOS,
    },
    {
        name: OPERA,
        major: '3',
        os: iOS,
    },
    {
        name: OPERA,
        major: '63',
        os: ANDROID,
    },
    {
        name: FACEBOOK,
        os: ANDROID,
    },
    {
        name: FACEBOOK,
        os: iOS,
    },
    {
        name: INSTAGRAM,
        os: iOS,
    },
    {
        name: WEBKIT,
        major: '602', // Safari 10
        os: iOS,
    },
    {
        name: CHROME_WEBVIEW,
        major: '50', // May 2016
        os: ANDROID,
    },
];

export default supportedBrowsers;
