import React, { useState, useMemo, FormEvent, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import {
    getDeliveryAddressText,
    isLocationClosed,
    isLocationOrderAheadAvailable,
    isLocationDeliveryAvailable,
} from '../../../../../../lib/locations';
import { getOpeningHoursInfo } from '../../../../../../common/helpers/locations/getOpeningHoursInfo';
import { usePickupTab, UsePickupTabHook } from '../../../../../../common/hooks/usePickupTab';
import useOrderLocation from '../../../../../../redux/hooks/useOrderLocation';
import useDomainMenu from '../../../../../../redux/hooks/useDomainMenu';

import { InspireTab, InspireTabPanel, InspireTabSet } from '../../../../../atoms/tabset';
import BrandIcon from '../../../../../atoms/BrandIcon';
import LocationMethodTab from './locationMethodTab';

import styles from './locationTabs.module.css';

import { OrderLocationMethod } from '../../../../../../redux/orderLocation';
import { LocationTabPaths } from '../../../../locationsPageContent/locationsPageContentPickupAndDeliveryFlow/types';
import { useBag, useConfiguration } from '../../../../../../redux/hooks';
import useBagAvailableItemsCounter from '../../../../../../common/hooks/useBagAvailableItemsCounter';
import { useBagAnalytics } from '../../../../../../common/hooks/useBagAnalytics';
import { getPreviousLocationData } from '../../../../../../common/helpers/getPreviousLocationData';
import { useLocationSearchUrl } from '../../../../../../common/hooks/useLocationSearchUrl';

interface ILocationTabsProps {
    closeDropdown: () => void;
    onCheckoutInvalidTimeError?: string;
    clearInvalidTimeError?: () => void;
}

const LocationTabs = (props: ILocationTabsProps): JSX.Element => {
    const { closeDropdown, onCheckoutInvalidTimeError, clearInvalidTimeError } = props;
    const { pushGtmLocationOrder, pushGtmChangeLocation } = useBagAnalytics();

    const bag = useBag();
    const router = useRouter();
    const { backToParam, searchLocationPath } = useLocationSearchUrl();

    const {
        method,
        pickupAddress,
        deliveryAddress,
        actions: { setMethod, setPreviousLocation },
    } = useOrderLocation();

    const {
        actions: { getDomainMenu },
    } = useDomainMenu();

    const availableItemsQuantity = useBagAvailableItemsCounter();
    const {
        configuration: { isOAEnabled },
    } = useConfiguration();

    const isAvailableItemsInBag = availableItemsQuantity > 0;

    const tabIndexes = { PICKUP: 0, DELIVERY: 1 };
    const defaultActiveTab = method === OrderLocationMethod.DELIVERY ? tabIndexes.DELIVERY : tabIndexes.PICKUP;
    const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);

    const handleChangeTab = (_: FormEvent<HTMLButtonElement>, newValue?: number): void => {
        setActiveTab(newValue);
    };

    const previousLocationData = getPreviousLocationData({ method, pickupAddress, deliveryAddress });

    const isPickupOnlineOrderUnavailable = useMemo(() => {
        if (pickupAddress) {
            const isOnlineOrderAvailable = isLocationOrderAheadAvailable(pickupAddress, isOAEnabled);
            const isStatusClosed = isLocationClosed(pickupAddress);
            return !(isOnlineOrderAvailable && !isStatusClosed);
        }
        return true;
    }, [pickupAddress, isOAEnabled]);

    const isDeliveryOnlineOrderUnavailable = useMemo(() => {
        if (deliveryAddress) {
            const isDeliveryAvailable = isLocationDeliveryAvailable(deliveryAddress.locationDetails, isOAEnabled);
            const isStatusClosed = isLocationClosed(deliveryAddress.locationDetails);
            return !(isDeliveryAvailable && !isStatusClosed);
        }
        return true;
    }, [deliveryAddress, isOAEnabled]);

    const pickupTabData = usePickupTab();
    const deliveryTabData: UsePickupTabHook = useMemo(() => {
        const data = {
            title: 'Delivery Address',
            displayName: null,
            address: null,
            changeLocationText: 'Change Address',
            ctaText: bag.bagEntriesCount ? 'continue delivery order' : 'start delivery order',
            storeStatus: null,
        };

        if (deliveryAddress) {
            data.displayName = deliveryAddress.deliveryLocation.businessName;
            data.address = getDeliveryAddressText(deliveryAddress);
            const { statusText } = getOpeningHoursInfo(deliveryAddress.locationDetails);
            data.storeStatus = statusText;
        }

        return data;
    }, [deliveryAddress, bag.bagEntriesCount]);

    const onBagAndTabClose = () => {
        closeDropdown();
        if (!isAvailableItemsInBag) {
            bag.actions.toggleIsOpen({ isOpen: false });
        }
    };
    // pickup tab handlers
    const pickupChangeLocationClick = (e: SyntheticEvent) => {
        e.preventDefault();
        onBagAndTabClose();

        const zip = pickupAddress?.contactDetails?.address?.postalCode;

        pushGtmChangeLocation();

        return router.push({ pathname: searchLocationPath, query: { q: zip, intop: true, ...backToParam } });
    };
    const pickupStartOrderClick = () => {
        setPreviousLocation(previousLocationData);
        if (method !== OrderLocationMethod.PICKUP) {
            getDomainMenu(pickupAddress, OrderLocationMethod.PICKUP);
        }

        setMethod(OrderLocationMethod.PICKUP);
        onBagAndTabClose();

        pushGtmLocationOrder(OrderLocationMethod.PICKUP, pickupAddress.id);

        if (isPickupOnlineOrderUnavailable) {
            const zip = pickupAddress?.contactDetails?.address?.postalCode;

            return router.push({
                pathname: searchLocationPath,
                query: { t: LocationTabPaths.PICKUP, q: zip, intop: true, ...backToParam },
            });
        }

        if (!isAvailableItemsInBag) {
            return router.push('/menu');
        }
    };

    // delivery tab handlers
    const deliveryChangeLocationClick = (e: SyntheticEvent) => {
        e.preventDefault();
        onBagAndTabClose();

        const address = deliveryAddress?.deliveryLocation?.addressLine1;

        pushGtmChangeLocation();

        return router.push({
            pathname: searchLocationPath,
            query: { t: LocationTabPaths.DELIVERY, dq: address, ...backToParam },
        });
    };
    const deliveryStartOrderClick = () => {
        setPreviousLocation(previousLocationData);
        if (method !== OrderLocationMethod.DELIVERY) {
            getDomainMenu(deliveryAddress.locationDetails, OrderLocationMethod.DELIVERY);
        }

        setMethod(OrderLocationMethod.DELIVERY);
        onBagAndTabClose();

        pushGtmLocationOrder(OrderLocationMethod.DELIVERY, deliveryAddress.locationDetails.id);

        if (isDeliveryOnlineOrderUnavailable) {
            const address = deliveryAddress?.deliveryLocation?.addressLine1;

            return router.push({
                pathname: searchLocationPath,
                query: { t: LocationTabPaths.DELIVERY, dq: address, ...backToParam },
            });
        }

        if (!isAvailableItemsInBag) {
            return router.push('/menu');
        }
    };

    return (
        <>
            <div className={styles.orderMethodTabSet}>
                <InspireTabSet
                    value={activeTab}
                    variant="fullWidth"
                    onChange={handleChangeTab}
                    className={styles.orderMethodTabs}
                >
                    <InspireTab
                        label="Pickup"
                        icon={<BrandIcon icon="order-pickup" size="xs" />}
                        id="locationSelect-tab-0"
                        aria-controls="locationSelect-tabpanel-0"
                        className={styles.orderMethodTab}
                    />
                    <InspireTab
                        label="Delivery"
                        icon={<BrandIcon icon="order-delivery" size="xs" />}
                        id="locationSelect-tab-1"
                        aria-controls="locationSelect-tabpanel-1"
                        className={styles.orderMethodTab}
                    />
                </InspireTabSet>
            </div>
            <InspireTabPanel
                value={activeTab}
                index={tabIndexes.PICKUP}
                id="locationSelect-tabpanel-0"
                aria-labelledby="locationSelect-tab-0"
                className={classnames(styles.tabPanel, styles.pickup)}
            >
                <LocationMethodTab
                    {...pickupTabData}
                    method={OrderLocationMethod.PICKUP}
                    handleStartOrderClick={pickupStartOrderClick}
                    handleChangeLocationClick={pickupChangeLocationClick}
                    closeDropdown={closeDropdown}
                    onCheckoutInvalidTimeError={onCheckoutInvalidTimeError}
                    clearInvalidTimeError={clearInvalidTimeError}
                />
            </InspireTabPanel>
            <InspireTabPanel
                value={activeTab}
                index={tabIndexes.DELIVERY}
                id="locationSelect-tabpanel-1"
                aria-labelledby="locationSelect-tab-1"
                className={classnames(styles.tabPanel, styles.delivery)}
            >
                <LocationMethodTab
                    {...deliveryTabData}
                    method={OrderLocationMethod.DELIVERY}
                    handleStartOrderClick={deliveryStartOrderClick}
                    handleChangeLocationClick={deliveryChangeLocationClick}
                    closeDropdown={closeDropdown}
                />
            </InspireTabPanel>
        </>
    );
};

export default LocationTabs;
