import React, { ReactElement } from 'react';
import InspireTooltip from '../../../atoms/Tooltip';
import Icon from '../../../atoms/BrandIcon';

import styles from './index.module.css';

interface INavigationTooltip {
    children: ReactElement;
    titleTooltip: string;
    isDropdownOpen?: boolean;
}

const ToolTipText = ({ tooltipTitle }): JSX.Element => (
    <div>
        <Icon icon="action-check" size="xs" variant="colorful" className={styles.checkIconTooltip} />
        {tooltipTitle}
    </div>
);

const NavigationTooltip = ({ children, titleTooltip, isDropdownOpen }: INavigationTooltip): JSX.Element => {
    return (
        <InspireTooltip
            tooltipClassName={styles.tooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={titleTooltip ? <ToolTipText tooltipTitle={titleTooltip} /> : ''}
            placement="bottom"
            theme="light"
            arrow
            open={isDropdownOpen ? !!titleTooltip && !isDropdownOpen : !!titleTooltip}
            PopperProps={{
                disablePortal: true,
            }}
        >
            {children}
        </InspireTooltip>
    );
};

export default NavigationTooltip;
